import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { StaticImage } from "gatsby-plugin-image";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            wip
          }
    }
`;

const MainText = styled.p`
    font-size: 3em;
    color: #d6d4d4;
    font-family: "Roboto";
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 4.25rem;
    padding: 0 0 0 4rem;
    @media (max-width: 1250px) {
      padding: 0;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 5rem;
    width: 90%;
    padding: 10rem 0;
    @media (max-width: 1250px) {
        position: relative;
        z-index: 2;
    }
`;
const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    flex-wrap: nowrap;
`;
const RightContainer = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    @media (max-width: 1250px) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
`;
const Link = styled.a`
    text-decoration: none;
`;

const Img = styled.img`
  width: 20rem;
  height: 20rem;
`;
const Imgc = styled(Img)`
  width: 30rem;
`;

const WorkPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();

  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Saba Hossein - Work"
        description="Full Stack Web Developer with over 9 years of experience and with a large set of supporting skills. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, and many more."
        keywords={["saba"]}
        url={props.location.pathname}
      />
      <Container>
          <Link href="https://www.bahai.org"><StaticImage src="../../assets/image/bahaiorg.png"  alt={""} width={200} height={200} placeholder="blurred" layout="constrained" quality={100} transformOptions={{fit: "cover"}}/></Link>
          <Link href="https://www.rvilgroup.co.in"><StaticImage src="../../assets/image/raddhavallabh.png"  alt={""} width={200} height={200} placeholder="blurred" layout="constrained" quality={100} transformOptions={{fit: "cover"}}/></Link>
          <Link href="https://www.zhinusdelights.com/"><StaticImage src="../../assets/image/zhinusDelight.png"  alt={""} width={280} height={250} placeholder="blurred" layout="constrained" quality={100} transformOptions={{fit: "cover"}}/></Link>
          <Link href="https://wanderlusttourism.in/"><StaticImage src="../../assets/image/wanderlust.png"  alt={""} width={280} height={200} placeholder="blurred" layout="constrained" quality={100} transformOptions={{fit: "cover"}}/></Link>
          <Link href="https://www.varqaschool.com"><StaticImage src="../../assets/image/varqa.png"  alt={""} width={215} height={200} placeholder="blurred" layout="constrained" quality={100} transformOptions={{fit: "cover"}}/></Link>
          <Link href="https://www.eatos.com"><StaticImage src="../../assets/image/eatos.png"  alt={""} width={620} height={255} placeholder="blurred" layout="constrained" quality={100} transformOptions={{fit: "cover"}}/></Link>
      </Container>
    </>
  )
}

export default WorkPage
